<template>
  <div>
    <button
      class="btn btn-primary btn-sm"
      data-target="#myModal"
      data-toggle="modal"
      type="button"
    >
      {{ $t('string.choose_province') }} : {{ label }}
    </button>
    <div
      id="myModal"
      aria-labelledby="myModalLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal-dialog"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4
              id="myModalLabel"
              class="modal-title font-secondary"
            >
              {{ $t('string.choose_province') }}
            </h4>
          </div>
          <div class="modal-body">
            <div>
              <div class="tw-grid tw-grid-cols-4 tw-gap-3">
                <div
                  :class="{'tw-bg-primary' : provinceSelected === -1 }"
                  class="tw-border tw-border-[1px] tw-border-solid tw-border-gray-100 tw-bg-gray-400 tw-cursor-pointer tw-p-2 tw-w-full tw-rounded-lg tw-text-white hover:tw-bg-primary-dark"
                  @click="provinceSelected = -1"
                >
                  {{ $t('string.all') }}
                </div>
                <div
                  v-for="(province, key) in provinces"
                  :key="key"
                  :class="{'tw-bg-primary' : provinceSelected === province.id }"
                  class="tw-border tw-border-[1px] tw-border-solid tw-border-gray-100 tw-bg-gray-400 tw-cursor-pointer tw-p-2 tw-w-full tw-rounded-lg tw-text-white hover:tw-bg-primary-dark"
                  @click="provinceSelected = province.id"
                >
                  {{ province['name_' + $i18n.locale] }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              ref="close"
              class="btn btn-default"
              data-dismiss="modal"
              type="button"
            >
              {{ $t('button.close') }}
            </button>
            <button
              class="btn btn-primary"
              type="button"
              @click="onSelectProvince"
            >
              {{ $t('button.apply') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {mapFields} from "vuex-map-fields"

export default {
  name: "ProvinceCertificateProvinceFilter",
  data() {
    return {
      allProvinces: [],
      listAllCertificateProvinceProjects: this.can(this.$Permissions.backend.certificateProvince.occupancy.listAll),
      provinceSelected: -1
    }
  },
  computed: {
    ...mapFields('certificateOfOccupancy', {
      province_id: 'province_id'
    }),
    ...mapGetters('user', {
      userProvinces: 'provinces'
    }),
    provinces() {
      if (this.listAllCertificateProvinceProjects) {
        return this.allProvinces
      }
      if (this.userProvinces && Array.isArray(this.userProvinces)) {
        return this.userProvinces
      }
      return []
    },
    label() {
      if (this.provinceSelected !== -1) {
        const province = this.provinces.find(p => p.id === this.provinceSelected)
        if (province) {
          return province['name_' + this.$i18n.locale]
        }
      }
      return this.$t('string.all')
    }
  },
  async mounted() {
    await this.getProvince()
  },
  methods: {
    async getProvince() {
      await this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location')
        .then(({data}) => {
          if (data.data) {
            this.allProvinces = data.data
          }
        })
    },
    onSelectProvince() {
      this.$refs.close.click()
      this.province_id = this.provinceSelected
      this.$emit('change')
    }
  }
}
</script>

<style scoped>

</style>
